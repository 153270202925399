const URLS = {
    /**
     * 发布到腾讯云
     */
    "tencent": {
        COMMON_API: 'https://consul.mes.emergen.cn:17014/api',
        APS_API: 'https://zt.nk.emergen.cn:18067',
        SERVER_USER_API: 'https://pay.zt.emergen.cn/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'https://pay.zt.emergen.cn/api/usercenter/pc/v1',
        CONTRACT_API: 'https://pay.zt.emergen.cn/api/htcenter/ht-s2c/v1',
        ZCY_SHARE_API: 'https://pay.zt.emergen.cn/api/fx/fx-pc-s2c/v1',
        TG_SHARE_API: 'https://pay.zt.emergen.cn/api/fenxiao/api',
        SALE_API: 'https://pay.zt.emergen.cn/api/sale/api',
        PROP_API: 'https://pay.zt.emergen.cn/api/prop/api',
        GOV_API: 'https://pay.zt.emergen.cn/api/gov/api',
        PAY_API: "https://pay.zt.emergen.cn/api/pay/api",
        ORDER_API: "https://pay.zt.emergen.cn/api/order/api",
        BASEINFO_API: 'https://pay.zt.emergen.cn/api/baseinfo/api',
        FILE_API: "https://pay.zt.emergen.cn/api/oss/api",
        IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/prod/",
        MES_URL: "https://vue.mes.emergen.cn/",
        MES_STANDARD_URL: "https://vue.mes.emergen.cn/",
        PORTAL_URL: "https://htcenter.zt.emergen.cn",
        FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        MOOD_URL: "https://wvue.nk001.com",
        SUPPLIER_URL: "//manage.ainankang.com",
        MES_API: "https://gateway.mes.emergen.cn/api",
        CLOUD_LOGISTIC_API: "https://zt.nk.emergen.cn:6011/center",
        CLOUD_FINANCIAL_API: "https://zt.nk.emergen.cn:18077/center",
        CLOUD_FINANCIAL_URL: "https://scfvue.nk001.com/",
    },
    /**
     * 发布到k8s
     */
    "tencent_k8s": {
        COMMON_API: 'https://consul.mes.emergen.cn:17014/api',
        APS_API: 'https://apsvue.nk001.com',
        SERVER_USER_API: 'https://ztapi.nk001.com/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'https://ztapi.nk001.com/api/usercenter/pc/v1',
        CONTRACT_API: 'https://ztapi.nk001.com/api/htcenter/ht-s2c/v1',
        ZCY_SHARE_API: 'https://ztapi.nk001.com/api/fx/fx-pc-s2c/v1',
        TG_SHARE_API: 'https://ztapi.nk001.com/api/fenxiao/api',
        SALE_API: 'https://ztapi.nk001.com/api/sale/api',
        PROP_API: 'https://ztapi.nk001.com/api/prop/api',
        GOV_API: 'https://ztapi.nk001.com/api/gov/api',
        PAY_API: "https://ztapi.nk001.com/api/pay/api",
        ORDER_API: "https://ztapi.nk001.com/api/order/api",
        BASEINFO_API: 'https://ztapi.nk001.com/api/baseinfo/api',
        FILE_API: "https://ztapi.nk001.com/api/oss/api",
        IMG_URL_PRE: "https://zt.cdn.nk001.com/prod/",
        MES_URL: "https://mesvue.nk001.com/",
        MES_STANDARD_URL: "https://mesv2.nk001.com/",
        PORTAL_URL: "https://zthome.nk001.com",
        FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        MOOD_URL: "https://wvue.nk001.com",
        SUPPLIER_URL: "https://wvue.nk001.com",
        MES_API: "https://gateway.nk001.com/api",
        CLOUD_LOGISTIC_API: "https://blcvue.nk001.com/center",
        CLOUD_FINANCIAL_API: "https://scfvue.nk001.com/center",
        CLOUD_FINANCIAL_URL: "https://scfvue.nk001.com/",
        BLC_API: "https://blcapi.nk001.com",
        COPYRIGHT_URL: "https://design.nk001.com/",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/loading"
    },
    /**
     * 发布到微控云（演示） 
     */
    "tencent_wky": {
        COMMON_API: 'https://consul.mes.emergen.cn:17014/api',
        APS_API: 'http://apn.zc.emergen.cn:3050/nkapi/',
        SERVER_USER_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'http://zts.zc.emergen.cn:3050/api/usercenter/pc/v1',
        CONTRACT_API: 'http://zts.zc.emergen.cn:3050/api/htcenter/ht-s2c/v1',
        ZCY_SHARE_API: 'http://zts.zc.emergen.cn:3050/api/fx/fx-pc-s2c/v1',
        TG_SHARE_API: 'http://zts.zc.emergen.cn:3050/api/fenxiao/api',
        SALE_API: 'http://zts.zc.emergen.cn:3050/api/sale/api',
        PROP_API: 'http://zts.zc.emergen.cn:3050/api/prop/api',
        GOV_API: 'http://zts.zc.emergen.cn:3050/api/gov/api',
        PAY_API: "http://zts.zc.emergen.cn:3050/api/pay/api",
        ORDER_API: "http://zts.zc.emergen.cn:3050/api/order/api",
        BASEINFO_API: 'http://zts.zc.emergen.cn:3050/api/baseinfo/api',
        FILE_API: "http://zts.zc.emergen.cn:3050/api/oss/api",
        IMG_URL_PRE: "https://zt.cdn.nk001.com/wky/",
        MES_URL: "http://mes.zc.emergen.cn:3050/",
        MES_STANDARD_URL: "http://mes.zc.emergen.cn:3050/",
        PORTAL_URL: "http://zth.zc.emergen.cn:3050",
        FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        MOOD_URL: "http://wvu.zc.emergen.cn:3050",
        SUPPLIER_URL: "http://wvu.zc.emergen.cn:3050",
        MES_API: "http://mga.zc.emergen.cn:3050/api",
        CLOUD_LOGISTIC_API: "http://blv.zc.emergen.cn:3050/center",
        CLOUD_FINANCIAL_API: "http://scv.zc.emergen.cn:3050/center",
        CLOUD_FINANCIAL_URL: "http://scv.zc.emergen.cn:3050/",
        BLC_API: "http://bla.zc.emergen.cn:3050",
        COPYRIGHT_URL: "https://design.nk001.com/",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/loading"
    },
    /**
     * 发布到8006
     */
    "test": {
        COMMON_API: 'https://consul.mes.emergen.cn:16016/api',
        APS_API: 'https://consul.mes.emergen.cn:18067',
        SERVER_USER_API: 'https://test.emergen.cn/api/usercenter/uc/v1',
        SERVER_ITEM_API: 'https://test.emergen.cn/api/usercenter/pc/v1',
        CONTRACT_API: 'https://test.emergen.cn/api/htcenter/ht-s2c/v1',
        ZCY_SHARE_API: 'https://test.emergen.cn/api/fx/fx-pc-s2c/v1',
        TG_SHARE_API: 'https://test.emergen.cn/api/fenxiao/api',
        SALE_API: 'https://test.emergen.cn/api/sale/api',
        PROP_API: 'https://test.emergen.cn/api/prop/api',
        GOV_API: 'https://test.emergen.cn/api/gov/api',
        PAY_API: "https://test.emergen.cn/api/pay/api",
        ORDER_API: "https://test.emergen.cn/api/order/api",
        BASEINFO_API: 'https://test.emergen.cn/api/baseinfo/api',
        FILE_API: "https://test.emergen.cn/api/oss/api",
        IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/test/",
        MES_URL: "https://test.mes.emergen.cn/",
        MES_STANDARD_URL: "https://test.mes.emergen.cn/",
        MES_PORTAL_URL: "https://test.home.emergen.cn",
        PORTAL_URL: "https://test.home.emergen.cn/front",
        FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        MOOD_URL: "https://test.manage.ainankang.com",
        SUPPLIER_URL: "//test.manage.ainankang.com",
        MES_API: "https://test.emergen.cn/gateway/api",
        CLOUD_LOGISTIC_API: "https://consul.mes.emergen.cn/",
        CLOUD_FINANCIAL_API: "https://consul.mes.emergen.cn/center",
        CLOUD_FINANCIAL_URL: "https://consul.mes.emergen.cn:19017/",
        BLC_API: "https://consul.mes.emergen.cn:5004",
        COPYRIGHT_URL: "https://consul.mes.emergen.cn:19002/",
        XJS_URL: "https://consul.mes.emergen.cn:31082/#/loading"
    },
    "development": {
        COMMON_API: 'http://demo.emergen.cn:16015/api',
        APS_API: 'http://nkapsapi.deepsingularity.cn',
        SERVER_USER_API: 'http://demo.emergen.cn:8013/uc/v1',
        SERVER_ITEM_API: 'http://demo.emergen.cn:8013/pc/v1',
        CONTRACT_API: 'http://demo.emergen.cn:8021/ht-s2c/v1',
        ZCY_SHARE_API: 'http://demo.emergen.cn:8031/fx-pc-s2c/v1',
        TG_SHARE_API: 'http://demo.emergen.cn:4019/api',
        BASEINFO_API: 'http://demo.emergen.cn:4011/api',
        SALE_API: 'http://demo.emergen.cn:4003/api',
        PROP_API: 'http://demo.emergen.cn:4013/api',
        GOV_API: 'http://demo.emergen.cn:4007/api',
        PAY_API: "http://demo.emergen.cn:4009/api",
        ORDER_API: "http://demo.emergen.cn:4017/api",
        FILE_API: "http://demo.emergen.cn:4015/api",
        IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/",
        MES_URL: "http://demo.emergen.cn:8100/",
        MES_STANDARD_URL: "http://demo.emergen.cn:8100/",
        MES_PORTAL_URL: "http://demo.emergen.cn:8300",
        PORTAL_URL: "http://demo.emergen.cn:17004",
        FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        MOOD_URL: "https://test.manage.ainankang.com",
        SUPPLIER_URL: "//test.manage.ainankang.com",
        MES_API: "http://61.174.171.61:9000/api",
        CLOUD_LOGISTIC_API: "https://consul.mes.emergen.cn:6009",
        CLOUD_FINANCIAL_API: "https://consul.mes.emergen.cn:6009/center",
        CLOUD_FINANCIAL_URL: "http://1.116.89.202:40002/",
        BLC_API: "https://consul.mes.emergen.cn:6012",
        COPYRIGHT_URL: "http://consul.mes.emergen.cn:19001/",
        XJS_URL: "https://consul.mes.emergen.cn:31080/#/loading"

        // COMMON_API: 'https://consul.mes.emergen.cn:16016/api',
        // APS_API: 'https://consul.mes.emergen.cn:18067',
        // SERVER_USER_API: 'https://test.emergen.cn/api/usercenter/uc/v1',
        // SERVER_ITEM_API: 'https://test.emergen.cn/api/usercenter/pc/v1',
        // CONTRACT_API: 'https://test.emergen.cn/api/htcenter/ht-s2c/v1',
        // ZCY_SHARE_API: 'https://test.emergen.cn/api/fx/fx-pc-s2c/v1',
        // TG_SHARE_API: 'https://test.emergen.cn/api/fenxiao/api',
        // SALE_API: 'https://test.emergen.cn/api/sale/api',
        // PROP_API: 'https://test.emergen.cn/api/prop/api',
        // GOV_API: 'https://test.emergen.cn/api/gov/api',
        // PAY_API: "https://test.emergen.cn/api/pay/api",
        // ORDER_API: "https://test.emergen.cn/api/order/api",
        // BASEINFO_API: 'https://test.emergen.cn/api/baseinfo/api',
        // FILE_API: "https://test.emergen.cn/api/oss/api",
        // IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/test/",
        // MES_URL: "https://test.mes.emergen.cn/",
        // MES_PORTAL_URL: "https://test.home.emergen.cn",
        // PORTAL_URL: "https://test.home.emergen.cn/front",
        // FINANCIAL_CENTER_URL: "http://demo.emergen.cn:17004",
        // MOOD_URL: "https://test.manage.ainankang.com",
        // SUPPLIER_URL: "//test.manage.ainankang.com",
        // MES_API: "https://test.emergen.cn/gateway/api",
        // CLOUD_LOGISTIC_API: "https://consul.mes.emergen.cn/",
        // CLOUD_FINANCIAL_API: "https://consul.mes.emergen.cn/center",
        // CLOUD_FINANCIAL_URL: "https://consul.mes.emergen.cn:19017/",
        // BLC_API: "https://consul.mes.emergen.cn:5004",
        // COPYRIGHT_URL: "https://consul.mes.emergen.cn:19002/",
        // XJS_URL: "https://consul.mes.emergen.cn:31080/#/loading"
    }
}
/**
 * 用户管理api (postToUserServer)
 */
export const SERVER_USER_API = URLS[process.env.VUE_APP_TITLE].SERVER_USER_API;
/**
 * 商品交易api (postToItemServer)
 */
export const SERVER_ITEM_API = URLS[process.env.VUE_APP_TITLE].SERVER_ITEM_API;
/**
 * 合同中心api (postToContractServer)
 */
export const CONTRACT_API = URLS[process.env.VUE_APP_TITLE].CONTRACT_API;
/**
 * 文件服务api
 */
export const FILE_API = URLS[process.env.VUE_APP_TITLE].FILE_API;
/**
 * 图片服务
 */
export const IMG_URL_PRE = URLS[process.env.VUE_APP_TITLE].IMG_URL_PRE;
/**
 * 静态资源服务
 */
export const STATIC_URL_PRE = "https://cdn.file.nk.emergen.cn/";
/**
 * 销售公司api (postToSaleServer)
 */
export const SALE_API = URLS[process.env.VUE_APP_TITLE].SALE_API;
/**
 * 销售公司分销api (张朝云--postToZCYShareServer)
 */
export const ZCY_SHARE_API = URLS[process.env.VUE_APP_TITLE].ZCY_SHARE_API;
/**
 * 销售公司分销api (田果--postToZCYShareServer)
 */
export const TG_SHARE_API = URLS[process.env.VUE_APP_TITLE].TG_SHARE_API;
/**
 * 销售公司道具api (postToPropServer)
 */
export const PROP_API = URLS[process.env.VUE_APP_TITLE].PROP_API;
/**
 * 政府政策api (postToGovServer)
 */
export const GOV_API = URLS[process.env.VUE_APP_TITLE].GOV_API;
/**
 * 基础资料() 
 */
export const BASEINFO_API = URLS[process.env.VUE_APP_TITLE].BASEINFO_API;
/**
 * 支付api (postToPayServer)
 */
export const PAY_API = URLS[process.env.VUE_APP_TITLE].PAY_API;
/**
 * 交易中心api (postToOrderServer)
 */
export const ORDER_API = URLS[process.env.VUE_APP_TITLE].ORDER_API;
/**
 * MES前端url
 */
export const MES_URL = URLS[process.env.VUE_APP_TITLE].MES_URL;

/**
 * MES标准版前端url
 */
export const MES_STANDARD_URL = URLS[process.env.VUE_APP_TITLE].MES_STANDARD_URL;

/**
 * MES前端url(云工厂、共享中心--)
 */
export const MES_PORTAL_URL = URLS[process.env.VUE_APP_TITLE].MES_PORTAL_URL;
/**
 * MES前端url(木材超市)
 */
export const MOOD_URL = URLS[process.env.VUE_APP_TITLE].MOOD_URL;
/**
 * MES前端url(供应商)
 */
export const SUPPLIER_URL = URLS[process.env.VUE_APP_TITLE].SUPPLIER_URL;
/**
 * 外包前端url(门户四大中心)
 */
export const PORTAL_URL = URLS[process.env.VUE_APP_TITLE].PORTAL_URL;
/**
 * 外包前端url(门户金融服务中心)
 */
export const FINANCIAL_CENTER_URL = URLS[process.env.VUE_APP_TITLE].FINANCIAL_CENTER_URL;
/**
 * Mes url
 */

export const MES_API = URLS[process.env.VUE_APP_TITLE].MES_API;
/**
 *  云版权
 */
export const COPYRIGHT_URL = URLS[process.env.VUE_APP_TITLE].COPYRIGHT_URL;
/**
 * Mes 云物流
 */
export const CLOUD_LOGISTIC_API = URLS[process.env.VUE_APP_TITLE].CLOUD_LOGISTIC_API;
/**
 * 云金融
 */
export const CLOUD_FINANCIAL_API = URLS[process.env.VUE_APP_TITLE].CLOUD_FINANCIAL_API;
/**
 * 云金融
 */
export const CLOUD_FINANCIAL_URL = URLS[process.env.VUE_APP_TITLE].CLOUD_FINANCIAL_URL;
/**
 * 当前域名
 */
export const BASE_URL = `${window.location.origin}`;
/**
 * APS 信息 url
 */
export const APS_API = URLS[process.env.VUE_APP_TITLE].APS_API;

/**
 * APS 信息 url
 */
export const BLC_API = URLS[process.env.VUE_APP_TITLE].BLC_API;

/**
 *  通用api新
 */
export const COMMON_API = URLS[process.env.VUE_APP_TITLE].COMMON_API;

/**
 *  讯集思
 */
export const XJS_URL = URLS[process.env.VUE_APP_TITLE].XJS_URL
