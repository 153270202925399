export const demandTypeOption = [
  {
    optionValue: "加工需求",
    optionCode: "peitao"
  },
  {
    optionValue: "设计需求",
    optionCode: "gaikuang"
  },
  {
    optionValue: "打样",
    optionCode: "dayang"
  }
]

export const contractOption = [
  // {
  //   optionValue: "版权购买",
  //   optionCode: "peitao"
  // },
  {
    optionValue: "需求定制",
    optionCode: "gaikuang"
  },
  {
    optionValue: "打样",
    optionCode: "dayang"
  }
]

export const designCompany = [
  {
    id: 1,
    name: '赣州睿典家居设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 2,
    name: '赣州嘉玛家居设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 3,
    name: '赣州市挞酷米家居设计有限公司',
    submitAt: '2019.11'
  },
  {
    id: 4,
    name: '赣州市南康区深璞设计有限公司',
    submitAt: '2019.6.21'
  },
  {
    id: 5,
    name: '赣州众荣家居设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 6,
    name: '赣州市宽图家居设计有限公司',
    submitAt: '2019.11'
  },
  {
    id: 7,
    name: '赣州道合家居设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 8,
    name: '江西依云家居设计有限公司',
    submitAt: '2019.5'
  },
  {
    id: 9,
    name: '赣州璞尚家具设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 10,
    name: '赣州楚和家具设计有限公司',
    submitAt: '2019.4.2'
  },
  {
    id: 11,
    name: '深圳市墨本家具设计有限公司赣州市南康分公司',
    submitAt: '2019.4.2'
  },
  {
    id: 12,
    name: '赣州恒度家具设计有限公司',
    submitAt: '2019.4.2'
  }
]