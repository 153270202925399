import Vue from 'vue'
import Vuex from 'vuex'
import { setLocalStorge } from '@/service/common';
import router from '@/router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser: null,
    logedAutoAuthInfo: null,
    ShopNum: 0,
    regStep: 1,
    history: null
  },
  getters: {
    getShopNum(state) {
      return state.ShopNum
    },
    getHistory(state) {
      return state.logedAutoAuthInfo
    },
  },
  mutations: {
    setLogedUser(state, user) {
      state.loginUser = user;
    },
    setHistory1(state, logedAutoAuthInfo) {
      console.error('mutations')
      state.logedAutoAuthInfo = logedAutoAuthInfo;
    },
    setHistory(state, path) {
      state.history = path;
    },
  },
  actions: {
    async setLogedUser({ commit }, user) {
      await setLocalStorge('logedUser', user);
      // 适配跳转mes门户
      // setLocalStorge('logedUser', user);
      commit('setLogedUser', user);
    },
    /**
     * 更新登录账号的部分属性,比如refreshToken/token
     * @param {*} param0 
     * @param {*} user 
     */
    async updateLogedUser({ commit, state }, user) {
      let newUser = Object.assign(state.loginUser, user);
      await setLocalStorge('logedUser', newUser);
      commit('setLogedUser', newUser);
    },
    async setHistory1({ commit }, logedAutoAuthInfo) {
      console.error('dispatch')
      commit("setHistory1", logedAutoAuthInfo)
    }
  },
  modules: {
  }
})
