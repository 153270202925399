/**
 * 销售公司分销管理模块
 */
export const agentRoutes = [
  {
    path: "/agent/agentGoods",
    meta: { title: "分销商品库" },
    component: () => import("@/views/agent/goods/AgentGoods.vue")
  },
  {
    path: "/agent/agentGoods/addSelfGoods",
    meta: { title: "自有商品" },
    component: () => import("@/views/agent/goods/AddSelfGoods.vue")
  },
  {
    path: "/agent/agentGoods/ItemSku/:id",
    meta: { title: "自有商品" },
    component: () => import("@/views/agent/goods/ItemSku.vue")
  },
  {
    path: "/agent/agentSet",
    meta: { title: "代理商设置" },
    component: () => import("@/views/agent/AgentSet.vue")
  },
  {
    path: "/agent/agentOrder",
    meta: { title: "分销订购管理" },
    component: () => import("@/views/agent/AgentOrder.vue")
  },
  {
    path: "/agent/agentOrder/zcgdd/:id",
    meta: { title: "核对订单信息" },
    component: () => import("@/views/agent/Zcgdd.vue")
  },
  {
    path: "/agent/agentOrder/detail/:id",
    meta: { title: "分销订购管理详情" },
    component: () => import("@/views/agent/AgentODetail.vue")
  },
  {
    path: "/agent/txgl",
    meta: { title: "商品分类" },
    component: () => import("@/views/agent/txgl/Txgl.vue")
  },
  {
    path: "/agent/txgl/goodCat",
    meta: { title: "分类下的商品" },
    component: () => import("@/views/agent/txgl/GoodCat.vue")
  },
  {
    path: "/agent/agentData",
    meta: { title: "数据统计" },
    component: () => import("@/views/agent/AgentData.vue")
  },
];
