var render = function render(_c,_vm){return _c('div',[(_vm.props.type === 1)?_c('div',{staticClass:"order-btm"},[_c('table',[_vm._m(0),(
          _vm.props.item1 &&
          _vm.props.item1.mesInfo &&
          _vm.props.item1.mesInfo.list.length > 0
        )?_c('tbody',_vm._l((_vm.props.item1.mesInfo.list),function(n,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(n.planNo))]),_c('td',[_vm._v(_vm._s(n.plannedQuantity))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(n.startDate,"YYYY/MM/DD HH:mm:ss")))]),_c('td',[_vm._v(_vm._s(_vm._f("moment")(n.endDate,"YYYY/MM/DD HH:mm:ss")))]),_c('td',[_vm._v(_vm._s(n.due))]),_c('td',[_vm._v(_vm._s(n.plannedShort ? "否" : "是"))])])}),0):_vm._e()]),(
        !_vm.props.item1.mesInfo ||
        (_vm.props.item1.mesInfo && _vm.props.item1.mesInfo.list.length == 0)
      )?_c('div',[_vm._m(1)]):_vm._e()]):_c('div',{staticClass:"aps"},[_c('h3',[_vm._v("APS信息")]),_c('p',[_c('span',{staticClass:"label"},[_vm._v("订单交期")]),(_vm.props.item1 && _vm.props.item1[0] && _vm.props.item1[0].due)?_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("moment")(_vm.props.item1[0].due,"YYYY/MM/DD HH:mm:ss")))]):_vm._e()]),_c('p',[_c('span',{staticClass:"label"},[_vm._v("是否延期")]),(_vm.props.item1 && _vm.props.item1[0])?_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.props.item1[0].plannedShort ? "是" : "否"))]):_vm._e()])])])
}
var staticRenderFns = [function (_c,_vm){return _c('thead',[_c('tr',[_c('th',[_vm._v("批次号")]),_c('th',[_vm._v("已计划量")]),_c('th',[_vm._v("生产开始日期")]),_c('th',[_vm._v("生产结束日期")]),_c('th',[_vm._v("订单交期")]),_c('th',[_vm._v("是否延期")])])])
},function (_c,_vm){return _c('div',{staticStyle:{"font-size":"20px","color":"#cccccc"}},[_c('i',{staticClass:"iconfont icon-qiye",staticStyle:{"font-size":"20px"}}),_vm._v(" 暂无排产计划 ")])
}]

export { render, staticRenderFns }