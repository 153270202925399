/**
 * factory模块路由
 */
import store from '@/store';
// 为了解决登陆之后就不能在进入登录页
function checkLogin (to, from, next) {
  let curUser = store.state.loginUser; 
  console.log('checkLogin', curUser);
  if (curUser && curUser.token) {
    if (curUser && curUser.comp && curUser.comp.id) {
      next('/center');
    } else {
      next();
    }
  } else {
    next();
  }
}
export const registerRoutes = [
  {
    path: '/login',
    component: () => import('@/views/register/login.vue'),
    beforeEnter: checkLogin
  },
  {
    path: '/forget',
    component: () => import('@/views/register/forget.vue'),
  },
  {
    path: '/register',
    component: () => import('@/views/register/Register.vue')
  },
  {
    path: '/invite',
    component: () => import('@/views/register/Invite.vue'),
  },
  // 邀请注册第二步
  {
    path: '/join2',
    component: () => import('@/views/register/Join2.vue'),
  },
  // 选择企业类型
  // {
  //   path: '/create_comp',
  //   component: () => import('@/views/register/CreateComp.vue'),
  //   beforeEnter: authTokenGuard
  // },
];
