import "core-js";
import Vue from 'vue'
import { Layout,Menu,Icon,Anchor,Timeline } from 'ant-design-vue';
import { Carousel,CarouselItem,Dialog,Form,FormItem,Button,Checkbox,Col,Input,Message,Radio,RadioGroup,RadioButton,Steps,Step,Upload,Cascader,Divider,Table,TableColumn,Tree,MessageBox,Popover,Pagination,Select,CheckboxGroup,Option,Row,Rate,InputNumber,DatePicker,Tabs,TabPane,Switch,DropdownItem,DropdownMenu,Dropdown,ColorPicker,Progress,Tooltip,Tag,Loading,Collapse,CollapseItem } from 'element-ui';
import App from './App.vue'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import '@/static/iconfont.css'//引入字体库

// 过滤器
import filter from './filter';
for (let key in filter) {
    Vue.filter(key, filter[key]);
}

// 全局css
import './assets/styles/style.css'
import './assets/styles/index.less'

Vue.config.productionTip = false

Vue.use(VueMoment)

// 注册全局组件
import CustomComponent from "@/components/base";
Vue.use(CustomComponent);

// ant-design-vue
Vue.use(Layout);
Vue.use(Menu)
Vue.use(Icon);
Vue.use(Anchor);
Vue.use(Timeline);
// Vue.use(Upload);
// element-ui
Vue.use(Carousel);
Vue.use(CarouselItem);
Dialog.props.closeOnClickModal.default = false;
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Col);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Upload);
Vue.use(Cascader);
Vue.use(Divider);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Dropdown);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tree);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TabPane);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(Row);
Vue.use(Rate);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(ColorPicker);
Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(Loading);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

// Vue.prototype.$eventHub= Vue.prototype.$eventHub || new Vue();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

