/**
 * sale模块路由
 */
import { salePurchaseRoutes } from '@/views/sale/pur/routes'
export const saleRoutes = [
  {
    path: 'mall',
    component: () => import('@/views/sale/mall/Index.vue'),
    redirect: '/sale/mall/home',
    children: [
      { path: 'home', component: () => import('@/views/sale/mall/Home.vue'), },
      { path: 'home/design', component: () => import('@/views/sale/mall/design/Index.vue') },
      { path: 'home/design_detail/:id', component: () => import('@/views/sale/mall/design/Detail.vue') },
      { path: 'home/factory', component: () => import('@/views/sale/mall/factory/Index.vue') },
      { path: 'home/factory_detail/:id', component: () => import('@/views/sale/mall/factory/Detail.vue') },
      { path: 'search', component: () => import('@/views/sale/mall/Search.vue') },
    ]
  },
  {
    path: 'shop',
    component: () => import('@/views/sale/shop/Index.vue'),
    redirect: '/sale/shop/list',
    children: [
      { path: 'list', component: () => import('@/views/sale/shop/List.vue') },
      { path: 'info/:id', component: () => import('@/views/sale/shop/Info.vue') },
      {
        path: 'item', redirect: '/sale/shop/item/list', component: () => import('@/views/sale/shop/item/Index.vue'), children: [
          { path: 'list', component: () => import('@/views/sale/shop/item/List.vue') },
          { path: 'add/:shop_id/:product_id', component: () => import('@/views/sale/shop/item/Edit.vue') },
          { path: 'edit/:id', component: () => import('@/views/sale/shop/item/Edit.vue') },
          { path: 'sxj', component: () => import('@/views/sale/shop/item/Sxj.vue') },
        ]
      },
      {
        path: 'putaway', redirect: '/sale/shop/putaway/list', component: () => import('@/views/sale/shop/putaway/Index.vue'),children:[
          { path: 'list', component: () => import('@/views/sale/shop/putaway/List.vue') },
        ]
      },
      {
        path: 'order', redirect: '/sale/shop/order/list', component: () => import('@/views/sale/shop/order/Index.vue'), children: [
          { path: 'list/:shop_id', component: () => import('@/views/sale/shop/order/List.vue') },
          { path: 'detail', component: () => import('@/views/sale/shop/order/Detail.vue') },
        ]
      },
      { path: 'comment', component: () => import('@/views/sale/shop/Comment.vue') },
    ]
  },
  {
    path: 'pur',
    component: () => import('@/views/sale/pur/Index.vue'),
    children: salePurchaseRoutes
  },
  {
    path: 'furniture',
    component: () => import('@/views/sale/furniture/Furniture.vue'),
  },
  {
    path: 'designView',
    component: () => import('@/views/sale/designView/DesignView.vue'),
  },


]