<template functional>
  <div>
    <div class="order-btm" v-if="props.type === 1">
      <table>
        <thead>
          <tr>
            <th>批次号</th>
            <th>已计划量</th>
            <th>生产开始日期</th>
            <th>生产结束日期</th>
            <th>订单交期</th>
            <th>是否延期</th>
          </tr>
        </thead>
        <tbody
          v-if="
            props.item1 &&
            props.item1.mesInfo &&
            props.item1.mesInfo.list.length > 0
          "
        >
          <tr v-for="(n, i) in props.item1.mesInfo.list" :key="i">
            <td>{{ n.planNo }}</td>
            <td>{{ n.plannedQuantity }}</td>

            <td>{{ n.startDate | moment("YYYY/MM/DD HH:mm:ss") }}</td>
            <td>{{ n.endDate | moment("YYYY/MM/DD HH:mm:ss") }}</td>
            <td>{{ n.due }}</td>
            <td>{{ n.plannedShort ? "否" : "是" }}</td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="
          !props.item1.mesInfo ||
          (props.item1.mesInfo && props.item1.mesInfo.list.length == 0)
        "
      >
        <div style="font-size: 20px; color: #cccccc">
          <i style="font-size: 20px" class="iconfont icon-qiye"></i>
          暂无排产计划
        </div>
      </div>
    </div>
    <div v-else class="aps">
      <h3>APS信息</h3>
      <p>
        <span class="label">订单交期</span>
        <span
          class="value"
          v-if="props.item1 && props.item1[0] && props.item1[0].due"
          >{{ props.item1[0].due | moment("YYYY/MM/DD HH:mm:ss") }}</span
        >
      </p>
      <p>
        <span class="label">是否延期</span>
        <span class="value" v-if="props.item1 && props.item1[0]">{{
          props.item1[0].plannedShort ? "是" : "否"
        }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "aps",
};
</script>
<style lang="less" scoped>
.order-btm {
  padding: 18px 0;
  > div {
    padding: 10px 0;
    text-align: center;
    border: 1px solid #e6e6e6;
  }
  table {
    width: 100%;
    text-align: center;
    border: 1px solid #e6e6e6;
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    th {
      height: 40px;
      background-color: #f5f5f5;
    }
    td {
      height: 40px;
    }
  }
}
.aps {
  border: 1px solid #ccc;
  padding: 10px;

  .value {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #292929;
  }
}
</style>