/**
 * factory模块路由
 */
export const factoryRoutes = [
  // 设计超市(老)
  {
    path: "gmsj",
    // meta: { title: "设计超市" },
    // redirect: '/factory/gmsj',
    component: () => import("@/views/factory/Gmsj/Gmsj.vue"),
  },
  {
    path: "gmsj/bqxs/detail/:id",
    // meta: { title: "产品详情" },
    component: () => import("@/views/factory/Gmsj/BqxsDetail.vue")
  },
  {
    path: "gmsj/fchz/detail/:id",
    // meta: { title: "产品详情" },
    component: () => import("@/views/factory/Gmsj/FchzDetail.vue")
  },

  // 设计超市
  {
    path: "market",
    component: () => import("@/views/factory/market/Market.vue"),
  },
  {
    path: "market/single/detail/:id",
    // meta: { title: "产品详情" },
    component: () => import("@/views/factory/market/single/SingleDetail.vue")
  },
  {
    path: "market/series/detail/:id",
    // meta: { title: "产品详情" },
    component: () => import("@/views/factory/market/series/SeriesDetail.vue")
  },
  // 订单-设计版权购买
  {
    path: "designBq",
    component: () => import("@/views/factory/order/DesignBq.vue")
  },

  // 设计超市分成合作签约SKU
  {
    path: "hzzpQuote/:id",
    meta: { title: "填写销售信息" },
    component: () => import("@/views/factory/Hzzp/HzzpQuote.vue")
  },
  // 编辑SKU
  {
    path: "quote/:id",
    meta: { title: "自有商品" },
    component: () => import("@/views/factory/Zysp/ItemQuote.vue")
  },
  // 商品管理
  {
    path: "qygl",
    meta: { title: "版权购买" },
    component: () => import("@/views/factory/Qygl/Qygl.vue")
  },
  {
    path: "qygl/detail/:id",
    meta: { title: "商品详情" },
    component: () => import("@/views/factory/Qygl/QyglDetail.vue")
  },
  {
    path: "hzzp",
    meta: { title: "合作商品" },
    component: () => import("@/views/factory/Hzzp/Hzzp.vue")
  },
  {
    path: "hzzp/detail/:id",
    meta: { title: "合作商品详情" },
    component: () => import("@/views/factory/Hzzp/HzzpDetail.vue")
  },
  {
    path: "zysp",
    meta: { title: "自有商品" },
    component: () => import("@/views/factory/Zysp/Zysp.vue")
  },
  {
    path: "zysp/detail/:id",
    meta: { title: "自有商品详情" },
    component: () => import("@/views/factory/Zysp/zyspDetail.vue")
  },
  // 提交自有商品
  {
    path: "zysp/submit",
    meta: { title: "自有商品" },
    component: () => import("@/views/factory/Zysp/Submit.vue")
  },
  // 商品分类
  {
    path: "txgl",
    meta: { title: "商品分类" },
    component: () => import("@/views/factory/txgl/Txgl.vue")
  },
  // 商品分类
  {
    path: "txgl/goodcat",
    meta: { title: "商品分类" },
    component: () => import("@/views/factory/txgl/GoodCat.vue")
  },
  // 订单管理(集市采购订单)
  {
    path: "xsdd",
    meta: { title: "销售订单" },
    component: () => import("@/views/factory/saleManage/Xsdd.vue")
  },
  {
    path: "xsdd/detail/:id",
    meta: { title: "销售订单详情" },
    component: () => import("@/views/factory/saleManage/XsddDetail.vue")
  },
  // 订单管理(分销)
  {
    path: "agent",
    meta: { title: "分销订单" },
    component: () => import("@/views/factory/saleManage/agent/Agentdd.vue")
  },
  {
    path: "agent/detail/:id",
    meta: { title: "分销订单详情" },
    component: () => import("@/views/factory/saleManage/agent/AgentDetail.vue")
  },
  // 需求订单
  {
    path: "demandOrder",
    // meta: { title: "需求定制" },
    component: () => import("@/views/want/order/DemandOrder.vue")
  },
  {
    path: 'demandCustomize/customizeDetail/:id',
    meta: { title: "订单详情" },
    component: () => import("@/views/desgin/order/CustomizeDetail.vue")
  },
  // 销售手册
  {
    path: "xssc",
    meta: { title: "产品画册" },
    component: () => import("@/views/factory/saleManage/xssc/Xssc.vue")
  },
  // 销售手册详情
  {
    path: "xssc/detail/:id",
    meta: { title: "查看详情及签订合同" },
    component: () => import("@/views/factory/saleManage/xssc/XsscDetail.vue")
  },
  // 政府
  {
    path: "gov/cxghyssyssq",
    meta: { title: "产线规划送审与验收申请" },
    component: () => import("@/views/factory/gov/Cxghyssyssq.vue")
  },
  {
    path: "gov/jrcpsq",
    meta: { title: "金融产品申请" },
    component: () => import("@/views/factory/gov/Jrcpsq.vue")
  },
  {
    path: "gov/qyrg",
    meta: { title: "企业入规申请" },
    component: () => import("@/views/factory/gov/qyrg/Qyrg.vue")
  },
  {
    path: "gov/qyrg/detail/:id",
    meta: { title: "详情" },
    component: () => import("@/views/factory/gov/qyrg/QyrgDetail.vue")
  },
  {
    path: "gov/qyrg/addQyrg",
    meta: { title: "企业入规申请" },
    component: () => import("@/views/factory/gov/qyrg/AddQyrg.vue")
  },
  {
    path: "gov/qytgsq",
    meta: { title: "企业退规申请" },
    component: () => import("@/views/factory/gov/Qytgsq.vue")
  },
  {
    path: "gov/zjjmsq",
    meta: { title: "租金减免申请" },
    component: () => import("@/views/factory/gov/Zjjmsq.vue")
  },
  {
    path: "gov/designAllowance",
    meta: { title: "设计奖补申请" },
    component: () => import("@/views/factory/gov/design-allowance")
  },
  {
    path: "gov/designAllowance/detail/:id",
    meta: { title: "设计奖补申请" },
    component: () => import("@/views/factory/gov/design-allowance/Detail.vue")
  },
];
