/**
 * 销售公司我的需求模块路由
 */
export const wantRoutes = [
  { 
    path: 'wantIssue', 
    meta: { title: "需求发布" },
    component: () => import('@/views/want/wantIssue/WantIssue.vue') 
  },
  { 
    path: 'wantFollow', 
    meta: { title: "我的需求" },
    component: () => import('@/views/want/wantFollow/WantFollow.vue') 
  },
  { 
    path: 'wantFollow/want/detail/:id', 
    meta: { title: "需求详情" },
    component: () => import('@/views/want/wantFollow/WantDetail.vue') 
  },
  { 
    path: 'wantFollow/pipeList/:id',
    meta: { title: "需求应答" },
    component: () => import('@/views/want/wantFollow/PipeList.vue') 
  },
  { 
    path: 'wantFollow/pipe/detail/:wantId/:id', 
    meta: { title: "需求应答" },
    component: () => import('@/views/want/wantFollow/PipeDetail.vue') 
  },
  {
    path: "wantFollow/creatOrder",
    meta:{title:"创建订单"},
    component: () => import("@/views/want/wantFollow/CreatOrder.vue")
  },

  // 生意管理
  {
    path: "joinWant",
    meta:{title:"生意管理"},
    component: () => import("@/views/want/business/JoinWant.vue")
  },
  {
    path: "joinWant/pipe/:id",
    meta:{title:"生意详情"},
    component: () => import("@/views/want/business/Pipe.vue")
  },
  {
    path: "sellerOrder",
    meta:{title:"需求订单"},
    component: () => import("@/views/want/order/SellerOrder.vue")
  },
  {
    path: 'demandCustomize/customizeDetail/:id',
    meta: { title: "订单详情" },
    component: () => import("@/views/desgin/order/CustomizeDetail.vue")
  },
]