// 过滤价格
function priceFilt(price, utype) {
    let f_x;
    if (utype) {
        f_x = Math.round(price) / 100;
    } else {
        f_x = Math.round(price * 100) / 100;
    }
    let s_x = f_x.toString();
    let pos_decimal = s_x.indexOf('.');
    if (pos_decimal < 0) {
        pos_decimal = s_x.length;
        s_x += '.';
    }
    while (s_x.length <= pos_decimal + 2) { s_x += '0'; }
    let x = s_x.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) { x1 = x1.replace(rgx, '$1' + ',' + '$2'); }
    return x1 + x2;
}
// 百分比保留两位小数
function percentFilt(percent) {
    var f_x = Math.floor(percent) / 100;
    return f_x;
}
// 保留两位小数
function discountFilt(number) {
    var f_x = Math.round(number * 1000) / 100;
    return f_x;
}
// 过滤合同名称
import { contractOption, demandTypeOption } from "@/utils/constant";
function contractFilt(value) {
    return filtHandler(contractOption, value)
}
function demandFilt(value) {
    return filtHandler(demandTypeOption, value)
}

function filtHandler(list, value) {
    const obj = list.find(item => { return item.optionCode === value });
    return obj && obj.optionValue
}
export default {
    priceFilt,
    percentFilt,
    discountFilt,
    demandFilt,
    contractFilt
}