
<template>
  <div class="cropper">
    <div class="cropper-content">
      <!-- 剪裁框 -->
      <div class="cropper" style="width:500px;height:450px">
        <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :maxImgSize="option.maxImgSize" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixedBox="option.fixedBox" @realTime="realTime" :fixed="option.fixed" :fixedNumber="fixedNumber"></vueCropper>
      </div>
      <!-- 预览框 -->
      <div class="show-preview" :style="{'width': '450px', 'height': '450px',  'overflow': 'hidden', 'margin': '0 25px', 'display':'flex', 'align-items' : 'center'}">
        <div :style="previews.div" class="preview" style="width:100%;height:100%">
          <img :src="previews.url" :style="previews.img" style="width:100%;height:100%">
        </div>
      </div>
    </div>
    <div class="flex" style="padding-top:25px">
      <!-- 缩放旋转按钮 -->
      <div style="width:350px;" class="flex flex-justify-between">
        <el-button type="primary" size="small" icon="el-icon-zoom-in" @click="changeScale(1)"></el-button>
        <el-button type="primary" size="small" icon="el-icon-zoom-out" @click="changeScale(-1)"></el-button>
        <el-button type="primary" size="small" @click="rotateLeft">逆时针旋转</el-button>
        <el-button type="primary" size="small" @click="rotateRight">顺时针旋转</el-button>
      </div>
      <!-- 确认上传按钮 -->
      <div class="flex-1" style="text-align:center;">
        <el-button type="primary" size="small" @click="uploadImg('blob')">上传</el-button>
      </div>
    </div>
  </div>
</template>
 
<script>
import VueCropper from 'vue-cropper/example/src/vue-cropper/vue-cropper';
export default {
  components: { VueCropper },
  data () {
    return {
      previews: {}, // 预览数据
      option: {
        img: '', // 裁剪图片的地址  (默认：空)
        outputSize: 1, // 裁剪生成图片的质量  (默认:1)
        maxImgSize:1000, // 限制图片最大宽度和高度
        full: true, // 是否输出原图比例的截图 选true生成的图片会非常大  (默认:false)
        infoTrue:true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        outputType: 'png', // 裁剪生成图片的格式  (默认:jpg)
        canMove: true, // 上传图片是否可以移动  (默认:true)
        original: false, // 上传图片按照原始比例渲染  (默认:false)
        canMoveBox: true, // 截图框能否拖动  (默认:true)
        autoCrop: true, // 是否默认生成截图框  (默认:false)
        autoCropWidth: 450, // 默认生成截图框宽度  (默认:80%)
        autoCropHeight: 450, // 默认生成截图框高度  (默认:80%)
        fixedBox: false, // 固定截图框大小 不允许改变  (默认:false)
        fixed: true, // 是否开启截图框宽高固定比例  (默认:true)
        fixedNumber: [1, 1] // 截图框比例  (默认:[1:1])
      },
      downImg: '#'
    }
  },
  props: ['imgFile', 'fixedNumber'],
  created() {
      
  },
  methods: {
    changeScale (num) {
      // 图片缩放
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft () {
      // 向左旋转
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      // 向右旋转
      this.$refs.cropper.rotateRight()
    },
    Update () {
      // this.file = this.imgFile
      this.option.img = this.imgFile.url;
    },
    realTime (data) {
      // 实时预览
      this.previews = data
    },
    uploadImg (type) {
      // 将剪裁好的图片回传给父组件
      event.preventDefault()
      let that = this;
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          // data = window.URL.createObjectURL(data);
          that.$emit('upload', data)
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          that.$emit('upload', data)
        })
      }
    },
    // 将base64转换为文件
    dataURLtoFile (dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let len = bstr.length
      let u8arr = new Uint8Array(len)
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      }
      return new File([u8arr], filename, { type: mime })
    },
  },
}
</script>
<style scoped>
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cropper-content .cropper {
  width: 350px;
  height: 300px;
}
.cropper-content .show-preview {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  overflow: hidden;
  border: 1px solid #cccccc;
  background: #cccccc;
  margin-left: 40px;
}
.preview {
  overflow: hidden;
  border: 1px solid #cccccc;
  background: #cccccc;
}
</style>