/**
 * 我的企业模块路由
 */
export const contractRoutes = [
  {
    path: '/contract/contract',
    meta: { title: "经办合同" },
    component: () => import('@/views/contract/Contract.vue'),
  },
  {
    path: '/contract/stamp',
    meta: { title: "印章管理" },
    component: () => import('@/views/contract/Stamp.vue'),
  }
];
