/**
 * 我的企业模块路由
 */
export const compRoutes = [
  // {
  //   path: '/comp_cert',
  //   component: () => import('@/views/comp/Cert.vue'),
  // },
  {
    path: '/comp/base',
    component: () => import('@/views/comp/Base.vue'),
  },
  {
    path: '/comp/bank',
    component: () => import('@/views/comp/Bank.vue'),
  },
  {
    path: '/comp/legal',
    component: () => import('@/views/comp/Legal.vue'),
  },
  {
    path: '/comp/contact',
    component: () => import('@/views/contact/Contact.vue'),
  },
  {
    path: '/comp/roles',
    component: () => import('@/views/comp/Roles.vue'),
  },
  {
    path: '/comp/authorLog',
    component: () => import('@/views/contact/AuthorLog.vue'),
  },
  /** 
   * 资质
   */
  {
    path: "/comp/zizhi",
    meta: {
      title: "资质管理"
    },
    component: () => import("@/views/comp/zizhi/Base.vue")
  },
  {
    path: "/zizhi/view/:id",
    meta: { title: "查看企业资质" },
    component: () => import("@/views/comp/zizhi/Zizhi.vue")
  },
  /** 
   * 用户管理
   */
  {
    path: "/contactInfo",
    meta: { title: "联系平台" },
    component: () => import("@/views/comp/ContactInfo.vue")
  },
];
