import _ from 'lodash'
import store from '@/store'
import router from '@/router'
import { MES_PORTAL_URL, PORTAL_URL } from "@/config";
import { postToUserServer, getLocalStorge } from './common';
/**
 * 从本地存储初始化登陆信息
 */
export async function initAccount(token) {
    let user;
    if (token) {
        user = await loginByToken(token);
        if (user) user.token = token;
        console.log("user1", user);
        await store.dispatch('setLogedUser', user);
    } else {
        user = await getLocalStorge('logedUser');
        store.commit('setLogedUser', user);
    }
    console.log("curUser", user);
    // store.commit('setLogedUser', user);

}
// 公共分类
export async function getMergeDict(obj) {
    return await postToUserServer('/merge-dict', obj);
}
/**
 * 修改个人信息
 */
export async function modConfig(obj) {
    obj.user_id = store.state.loginUser.user.id;
    await modUserInfo(obj);
    let logedUser = await getLocalStorge('logedUser');
    let getLogedUser = await getLogedUserInfo();
    Object.assign(logedUser.user, getLogedUser);
    await store.dispatch('setLogedUser', logedUser);
    // await localForage.setItem('logedUser', logedUser);
    // store.commit('setLogedUser', logedUser);
}
/**
 * 修改指定用户信息
 * @param {*} obj 
 */
export async function modUserInfo(obj) {
    return await postToUserServer('/my-company/set-userinfo', obj);
}
/**
 * 发起个人认证
 * @param {*} obj 
 */
export async function userVerify(obj) {
    return await postToUserServer('/user/verify-person-info', obj);
}
/**
 * 修改手机号码
 */
export async function modMp(number, smsCode) {
    let obj = {
        user_id: store.state.loginUser.user.id,
        mobile: number,
        code: smsCode
    }
    await postToUserServer('/my-company/set-mobile', obj);
}

/**
 * 修改密码
 * @param {*} old_password 
 * @param {*} password 
 * @param {*} cpassword 
 */
export async function modPwd(old_password, password, cpassword) {
    let obj = {
        user_id: store.state.loginUser.user.id,
        old_passwd: old_password,
        new_passwd: password,
        new_passwd_confirmed: cpassword
    }
    await postToUserServer('/my-company/set-password', obj);
}
/**
 * 修改密码-验证旧密码是否正确
 */
export async function modPwdAgo(old_password) {
    await postToUserServer('/my-company/verify-my-password', { passwd: old_password });
}
/**
 * 清除登陆信息,回到首页
 */
export async function logOut() {
    await store.dispatch('setLogedUser', null);
    router.push('/home');
    // 退出无条件跳转外包前端门户五大中心
    // if (process.env.VUE_APP_TITLE == 'development') {
    //     if (location.hostname != "localhost") {
    //         location.href = PORTAL_URL;
    //     } else router.push('/home');
    // } 
    // else if (process.env.VUE_APP_TITLE == 'test') {
    //     location.href = PORTAL_URL;
    // } 
    // else {
    //     location.href = PORTAL_URL;
    //     // router.push('/home');
    // }
    // 退出登录无条件跳转mes门户
    // if (process.env.VUE_APP_TITLE!='development') {
    //     location.href = MES_PORTAL_URL+"?p_token=clear";
    // } else {
    //     router.push('/home');
    // }
    // await localForage.setItem('logedUser',null);
    // store.commit('setLogedUser', null);
}
/**
 * mes门户到中台登录跳转回mes门户自动清除中台登录信息
 */
export async function quit() {
    await store.dispatch('setLogedUser', null);
}
/**
 * 使用手机号+邀请码注册
 * @param {*} _obj 
 */
export async function createMobileAccountByInvite(_obj) {
    let resp = await postToUserServer('/register-by-invite/reg', _obj);
    let logedUser = Object.assign({}, store.state.loginUser, {
        user: resp.user,
        token: resp.token,
        comp: resp.comp
    });
    await store.dispatch('setLogedUser', logedUser);
}
/**
 * 注册时（验证邀请码）
 */
export async function verifyInviteCode(obj) {
    await postToUserServer('/register-by-invite/auth-invite-code', obj);
}

/**
 * 创建手机用户账号,并设置登陆态
 * @param {*} _obj 注册请求参数 
 * mobile ⼿机号码 
 * code ⼿机验证码 
 * captcha 验证码 
 * captcha_token 验证码token
 */
export async function createMobileAccount(_obj) {
    let resp = await postToUserServer('/register-by-mobile/reg', _obj);
    let user = Object.assign({}, store.state.loginUser, {
        user: resp.user,
        token: resp.token,
        refresh_token: resp.refresh_token,
        comp: resp.comp
    });
    await store.dispatch('setLogedUser', user);
}
/**
 * 查询手机号是否已经注册
 */
export async function createMobileAccountAgo(_obj) {
    return await postToUserServer('/login/is-mobile-reg', _obj);
}

/**
 * 获得当前登陆用户信息
 */
export async function getLogedUserInfo() {
    let userInfo = await postToUserServer('/my-company/get-myinfo', {});
    return userInfo;
    // if (userInfo.id) {
    //     return {
    //         //  ⽤户ID
    //         id: userInfo.id,
    //         //  登录⼿机号
    //         mobile: userInfo.mobile,
    //         //  姓名
    //         name: userInfo.name,
    //         //  性别 0未知,1男,2⼥
    //         gender: userInfo.gender,
    //         //  头像地址
    //         avatar: userInfo.avatar,
    //         //  个⼈介绍
    //         intro: userInfo.intro,
    //         //  电话
    //         phone: userInfo.phone,
    //         //  座机
    //         tel: userInfo.tel,
    //         //  邮箱
    //         mail: userInfo.mail,
    //         //  微信
    //         wechat: userInfo.wechat,
    //         //  号码
    //         qq: userInfo.qq,
    //         //  注册进度 0⼿机号⽅式注册，完成了⼿机号验证。
    //         //  1⼿机号⽅式注册，完成
    //         // 了基本信息和密码。
    //         // 10邀请⽅式注册，完成了
    //         // 邀请信息验证。
    //         // 2注册完成。
    //         step: userInfo.step,
    //         //  所属公司ID
    //         comp_id: userInfo.comp_id
    //     }
    // }
    // return null;
}
/**
 * 手机号+密码登陆
 * @param {*} _obj 
 */
export async function loginByMobileAndPwd(_obj) {
    let resp = await postToUserServer('/login-by-account/auth', {
        mobile: _obj.mobile,
        passwd: _obj.passwd,
        captcha: _obj.captcha,
        captcha_token: _obj.captchaToken,
        remember: _obj.remember,
    });
    let user = {
        user: resp.user,
        token: resp.token,
        refresh_token: resp.refresh_token,
        comp: resp.comp
    };
    await store.dispatch('setLogedUser', user);
    // await localForage.setItem('logedUser', user);
    // store.commit('setLogedUser', user);
    return resp
}
/**
 * 使用手机号登陆,并设置登陆态
 * @param {*} _obj 注册请求参数 
 * mobile ⼿机号码 
 * code ⼿机验证码 
 * captcha 验证码 
 * captcha_token 验证码token
 * @param {*} _this vue实例
 */
export async function loginByMobile(_obj, _this) {
    let resp = await postToUserServer('/login-by-mobile/auth', {
        mobile: _obj.mobile,
        code: _obj.code,
        captcha: _obj.captcha,
        captcha_token: _obj.captcha_token,
        remember: _obj.remember
    });
    let user = {
        user: resp.user,
        token: resp.token,
        refresh_token: resp.refresh_token,
        comp: resp.comp
    };
    await store.dispatch('setLogedUser', user);
    // await localForage.setItem('logedUser', user);
    // store.commit('setLogedUser', user);
    // _this.$router.push('/center');
}
/**
 * 根据token登录
 * @param {*} token 
 */
export async function loginByToken(token) {
    let resp = await postToUserServer('/login-by-token/auth', {
        token: token
    });
    return {
        user: resp.user,
        comp: resp.comp
    };
    // await store.dispatch('setLogedUser', user);
}
/**
 * 手机号重置密码+登录
 * @param {*} token 
 */
export async function findPasswordReset(obj) {
    let resp = await postToUserServer('/find-password/reset', obj);
    let user = {
        user: resp.user,
        token: resp.token,
        refresh_token: resp.refresh_token,
        comp: resp.comp
    };
    await store.dispatch('setLogedUser', user);
}
/**
 * 获取邀请码
 */
export async function genInviteCode() {
    return await postToUserServer('/my-company/create-invite-code');
}
/**
 * 发送邀请码
 * @param {*} invite_code 邀请码
 * @param {*} phones ⼿机号列表,多个⼿机号⽤英⽂逗号隔开
 */
export async function sendInviteCode(invite_code, phones) {
    return await postToUserServer('/my-company/send-invite', {
        invite_code: invite_code,
        mobiles: phones
    });
}
/**
 * 查询多个用户信息
 * @param {*} comp_id 
 * @param {*} user_ids 
 */
export async function getUserInfoByIds(comp_id, user_ids = []) {
    let users = await postToUserServer('/my-company/get-multi-userinfo', {
        comp_id: comp_id,
        user_ids: user_ids.join()
    });
    return Object.values(users);
}
/**
 * 查询企业可用角色列表
 * @param {*} compId 
 */
export async function getRolesByCompId(obj) {
    return await postToUserServer('/my-company/get-roles', obj);
}
/**
 * 搜索企业可用角色列表
 * @param {*} compId 
 */
export async function getRolesSearchMember(obj) {
    let res = await postToUserServer('/my-company/search-member', obj);
    return res;
}
/**
 * 获取角色详情
 * @param {*} compId 
 * @param {*} roleId 
 */
export async function getRoleInfo(compId, roleId) {
    let role = await postToUserServer('/my-company/get-role', {
        comp_id: compId,
        role_id: roleId
    });
    if (role.privs) role.privs = Object.values(role.privs);
    return role;
}

/**
 * 获得企业可分配的权限列表
 * @param {*} compId 
 */
export async function getPrivisByCompId(compId) {
    return await postToUserServer('/my-company/get-priv-struct', {
        comp_id: compId
    });
}
/**
 * 保存角色
 * @param {*} obj 
 */
export async function saveRole(obj) {
    if (obj.role_id) {
        return await postToUserServer('/my-company/set-role', obj);
    } else {
        return await postToUserServer('/my-company/add-role', obj);
    }

}

/**
 * 删除角色
 * @param {*} roleId 
 */
export async function delRole(compId, roleId) {
    return await postToUserServer('/my-company/delete-role', {
        comp_id: compId,
        role_id: roleId
    });
}
/**
 * 批量设置人员所属部门
 * @param {*} comp_id 
 * @param {*} user_ids 数组
 * @param {*} dept_ids 数组
 */
export async function setMembersDept(comp_id, user_ids, dept_ids) {
    return await postToUserServer('/my-company/set-members-dept', {
        comp_id: comp_id,
        user_ids: user_ids.join(),
        dept_ids: dept_ids.join()
    });
}
/**
 * 从该企业批量移除⽤户成员
 * @param {*} comp_id 
 * @param {*} user_ids 
 */
export async function delUserByIds(comp_id, user_ids) {
    return await postToUserServer('/my-company/remove-members', {
        comp_id: comp_id,
        user_ids: user_ids.join()
    });
}