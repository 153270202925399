/**
 * factory模块路由
 */

export const mobileRoutes = [
  {
    path: '/mobile/shop/:compid/:userid',
    component: () => import('@/views/mobile/Shop.vue')
  },
  {
    path: "/mobile/facIntro/:id",
    component: () => import("@/views/mobile/FacIntro.vue")
  },
  {
    path: '/mobile/shop/detail/:compid/:userid/:id',
    component: () => import('@/views/mobile/ShopDetail.vue')
  },
  // H5首页
  {
    path: 'home',
    component: () => import('@/views/mobile/Home.vue'),
  },
  {
    path: 'register',
    component: () => import('@/views/mobile/register/Register.vue')
  },
  {
    path: 'login',
    component: () => import('@/views/mobile/register/login.vue')
  },
  {
    path: 'forget',
    component: () => import('@/views/mobile/register/forget.vue')
  },
  {
    path: 'invite',
    component: () => import('@/views/mobile/register/Invite.vue'),
  },
  // 邀请注册第二步
  {
    path: 'join2',
    component: () => import('@/views/mobile/register/Join2.vue'),
  },
  // 选择企业类型
  // {
  //   path: 'create_comp',
  //   component: () => import('@/views/mobile/register/CreateComp.vue'),
  // },
  {
    path: 'survey',
    component: () => import('@/views/mobile/survey/Survey.vue'),
  },
  {
    path: 'survey4',
    component: () => import('@/views/mobile/survey/Survey4.vue')
  },
];
