import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { initAccount } from '@/service/account';
import { compRoutes } from '@/views/comp/routes'
import { payRoutes } from '@/views/pay/routes'
import { factoryRoutes } from '@/views/factory/routes'
import { desginRoutes } from '@/views/desgin/routes'
import { saleRoutes } from '@/views/sale/routes'
import { agentRoutes } from '@/views/agent/routes'
import { wantRoutes } from '@/views/want/routes'
import { contractRoutes } from '@/views/contract/routes'
import { mineRoutes } from '@/views/mine/routes'
import { dayangRoutes } from '@/views/dayang/routes'
import { mobileRoutes } from '@/views/mobile/routes'
import { registerRoutes } from '@/views/register/routes'
import { govRoutes } from '@/views/approve/routes'
import { apsRoutes } from '@/views/aps/routes'

Vue.use(VueRouter)

export function authTokenGuard(to, from, next) {
  let curUser = store.state.loginUser;
  // console.log('authTokenGuard', curUser);
  if (curUser && curUser.token) {
    next('/center');
  } else {
    next();
  }
}
const authCompGuard = (to, from, next) => {
  // console.log("to", to);
  let logedUser = store.state.loginUser;
  // console.log('authCompGuard', logedUser);
  if (logedUser && logedUser.token && logedUser.user) {
    // console.log(23423423423,store.state.history);
    // 链接携带token带回到登陆之前的页面
    if (store.state.history) {
      let hpath = store.state.history;
      store.commit("setHistory", null);
      next(hpath);
    } else {
      next();
    }
  } else {
    store.commit("setHistory", to.fullPath);
    // console.log(4312123123123);
    if (to.path == "/moodMarket") {
      next("/login");
    } else {
      next(checkMobile());
    }

  }
};
// 检测是手机端还是PC端
const checkMobile = () => {
  let isMobile = (document.body.clientWidth || document.documentElement.clientWidth) > 600;
  if (!isMobile) {
    return '/mobile/home';
  } else return '/home'; // pc端没登录直接
}
const routes = [
  {
    path: '/',
    redirect: '/center',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/Home.vue'),
        beforeEnter: authTokenGuard,
      },
      {
        path: 'cloudWorks',
        component: () => import('@/views/home/CloudWorks.vue'),
      },
      {
        path: 'industry',
        component: () => import('@/views/home/Industry.vue'),
      },
      {
        path: 'share',
        component: () => import('@/views/home/Share.vue'),
      },
      {
        path: 'about',
        component: () => import('@/views/home/About.vue'),
      },
      // 工作台
      {
        path: 'center',
        component: () => import('@/views/center/Center.vue'),
        beforeEnter: authCompGuard
      },
      // 个人设置
      {
        path: 'personSet/:id',
        meta: { title: "个人设置" },
        component: () => import('@/views/contact/PersonSet.vue'),
        beforeEnter: authCompGuard
      },
      // 登录注册相关路由
      {
        path: 'register',
        redirect: '/login',
        component: () => import('@/views/register/Index.vue'),
        children: registerRoutes,
      },
      // 我的企业相关路由
      {
        path: 'mycomp',
        redirect: '/comp/base',
        component: () => import('@/views/comp/Index.vue'),
        children: compRoutes,
        beforeEnter: authCompGuard
      },
      // 支付相关路由
      {
        path: 'pay',
        component: () => import('@/views/pay/Index.vue'),
        children: payRoutes,
        beforeEnter: authCompGuard
      },
      // 工厂
      {
        path: 'factory',
        component: () => import('@/views/factory/Index.vue'),
        children: factoryRoutes,
        beforeEnter: authCompGuard
      },
      // 销售公司
      {
        path: 'sale',
        component: () => import('@/views/sale/Index.vue'),
        children: saleRoutes,
        beforeEnter: authCompGuard
      },
      // mes
      {
        path: 'mes',
        component: () => import('@/views/mes/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 版权中心
      {
        path: 'copyright',
        component: () => import('@/views/copyright/index.vue'),
        beforeEnter: authCompGuard
      },
      // mes 云物流
      {
        path: 'cloudLogistic',
        component: () => import('@/views/cloudLogistic/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 云报表
      {
        path: 'cloudReportForm',
        component: () => import('@/views/cloudReportForm/index.vue'),
        beforeEnter: authCompGuard
      },
      // 云打印
      {
        path: 'cloudPrint',
        component: () => import('@/views/cloudPrint/index.vue'),
        beforeEnter: authCompGuard
      },
      // 云金融
      {
        path: 'cloudFinancial',
        component: () => import('@/views/cloudFinancial/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 木材超市
      {
        path: 'moodMarket',
        component: () => import('@/views/moodMarket/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 零部件超市
      {
        path: 'partsMarket',
        component: () => import('@/views/partsMarket/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 供应商公司
      {
        path: 'supplier',
        component: () => import('@/views/supplier/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 物流公司
      {
        path: 'logistic',
        component: () => import('@/views/logistic/Index.vue'),
        beforeEnter: authCompGuard
      },
      // 设计公司
      {
        path: 'desgin',
        component: () => import('@/views/desgin/Index.vue'),
        children: desginRoutes,
        beforeEnter: authCompGuard
      },
      // 打样中心
      {
        path: 'dayang',
        component: () => import('@/views/dayang/Index.vue'),
        children: dayangRoutes,
        beforeEnter: authCompGuard
      },
      // 分销管理
      {
        path: 'agent',
        component: () => import('@/views/agent/Index.vue'),
        children: agentRoutes,
        beforeEnter: authCompGuard
      },
      // 需求管理
      {
        path: 'want',
        component: () => import('@/views/want/Index.vue'),
        children: wantRoutes,
        beforeEnter: authCompGuard
      },
      // 需求管理
      {
        path: 'contract',
        component: () => import('@/views/contract/Index.vue'),
        children: contractRoutes,
        beforeEnter: authCompGuard
      },
      // 我的
      {
        path: 'mine',
        component: () => import('@/views/mine/Index.vue'),
        children: mineRoutes,
        beforeEnter: authCompGuard
      },
      {
        path: 'gov',
        component: () => import('@/views/approve/Index.vue'),
        children: govRoutes,
        beforeEnter: authCompGuard
      },
      {
        path: 'aps',
        component: () => import('@/views/aps/Index.vue'),
        children: apsRoutes,
        beforeEnter: authCompGuard
      },

    ]
  },
  {
    path: '/messager',
    component: () => import('@/views/messager/index.vue'),
  },
  // 签约
  {
    path: '/signok',
    component: () => import('@/views/single/SignOk.vue')
  },
  // 手机端
  {
    path: '/mobile',
    redirect: '/mobile/home',
    component: () => import('@/views/mobile/Index.vue'),
    children: mobileRoutes,
  },
  // 打样门户
  {
    path: '/dy_portal',
    component: () => import('@/views/single/dy_portal/Index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 初始化登陆态
  initAccount(to.query.p_token).then(() => {
    next();
  }).catch(err => {
    console.log(err);
  });
});
export default router
